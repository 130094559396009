import { useConfiguration } from '@arvesta-websites/configuration';
import {
  ChevronLeft,
  ChevronLeftSlider,
  ChevronRight,
  ChevronRightSlider,
  HeroBannerMaskInverted,
} from '@arvesta-websites/icons';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import Slider from 'react-slick';
import { tv } from 'tailwind-variants';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './slick-custom.css';

import { HeadingTag, withErrorBoundary } from '../../components';
import HeroBanner, { type HeroBannerProps } from '../HeroBanner';
import LogoCard, { type LogoCardProps } from '../LogoCard';

import CarouselItemCard, { type CarouselItemCardProps } from './CarouselItemCard';
import {
  StyledCardWrapper,
  StyledCarouselWrapper,
  StyledControl,
  StyledHeroContentWrapper,
  StyledIndicator,
  StyledIndicatorWrapper,
  StyledTitle,
  StyledWrapper,
} from './Styled';

interface CarouselPropsBase {
  title: string;
  titleTag: HeadingTag;
}

export type CarouselProps =
  | (CarouselPropsBase & {
      contentEntries: HeroBannerProps[];
      carouselType: 'Hero';
    })
  | (CarouselPropsBase & {
      contentEntries: CarouselItemCardProps[];
      carouselType: 'Image Card';
    })
  | (CarouselPropsBase & {
      contentEntries: LogoCardProps[];
      carouselType: 'Logo Card';
    });

const carouselSlidesToShow = (isHero: boolean, isLogo: boolean) => {
  if (isHero) {
    return 1;
  }

  if (isLogo) {
    return 4;
  }

  return 3;
};

const carouselResponsiveSettings = (isHero: boolean) => {
  if (!isHero) {
    return [
      {
        breakpoint: 1350,
        settings: {
          className: 'carousel-medium',
          slidesToScroll: 2,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          className: 'carousel-small',
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ];
  }

  return undefined;
};

const getSliderSettings = (isHero: boolean, isLogo: boolean, maskPresent?: boolean) => {
  const appendDots = (dots: Array<ReactElement>) => {
    const newDots = dots.map(dot => {
      if (dot.props['className'] === 'slick-active') {
        return React.cloneElement(dot, {}, <StyledIndicator active isHero={isHero} />);
      }
      return React.cloneElement(dot);
    });
    return (
      <StyledIndicatorWrapper isHero={isHero} maskPresent={maskPresent}>
        {newDots}
      </StyledIndicatorWrapper>
    );
  };

  return {
    appendDots,
    arrows: true,
    centerPadding: '0px',
    className: isHero ? 'carousel-fullWidth' : 'carousel-large',
    customPaging: () => <StyledIndicator isHero={isHero} />,
    dots: true,
    infinite: true,
    responsive: carouselResponsiveSettings(isHero),
    slidesToScroll: carouselSlidesToShow(isHero, isLogo),
    slidesToShow: carouselSlidesToShow(isHero, isLogo),
    speed: 500,
  };
};

type ArrowProps = {
  isHero: boolean;
  handleClick: () => void;
  isInfinite: boolean;
  windowWidth: number;
  side: 'right' | 'left';
};

const ArrowIcon = ({ side }: Pick<ArrowProps, 'side'>) => {
  return side === 'right' ? <ChevronRightSlider /> : <ChevronLeftSlider />;
};

const SliderArrow = ({ isHero, isInfinite, windowWidth, side, handleClick }: ArrowProps) => {
  const config = useConfiguration();
  if (!isInfinite && windowWidth > 1350) {
    return null;
  }

  const variant = isHero ? 'hero' : 'regular';
  return (
    <StyledControl
      variant={variant}
      side={side}
      maskPresent={config.heroBanner.mask}
      className={config.heroBanner?.mask ? 'shouldhavemask' : undefined}
      onClick={handleClick}
    >
      <ArrowIcon side={side} />
    </StyledControl>
  );
};

type CarouselWrapperProps = {
  title: string;
  titleTag: HeadingTag;
  isHero: boolean;
  windowWidth: number;
  shouldBeInfinite: boolean;
  sliderRef: React.RefObject<Slider>;
  children: React.ReactNode;
};
const heroBannerCarousel = tv({
  slots: {
    imageWrapper: 'relative inline-block w-full h-[327px]',
    overlay: 'absolute w-full h-full top-0 left-0  mt-px z-10',
  },
});

type HeroBannerWrapperProps = Pick<CarouselWrapperProps, 'isHero' | 'windowWidth' | 'shouldBeInfinite' | 'children'>;
const HeroBannerWrapper = ({ isHero, windowWidth, shouldBeInfinite: isInfinite, children }: HeroBannerWrapperProps) => {
  if (isHero) {
    const { imageWrapper } = heroBannerCarousel();
    return <div className={imageWrapper()}>{children}</div>;
  }

  return (
    <StyledCarouselWrapper isHero={isHero} windowWidth={windowWidth} isInfinite={isInfinite}>
      {children}
    </StyledCarouselWrapper>
  );
};

const toDataUrl = (Mask: () => JSX.Element) =>
  `data:image/svg+xml,${encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<Mask />))}`;

const CarouselWrapper = ({
  title,
  titleTag,
  isHero,
  windowWidth,
  shouldBeInfinite,
  sliderRef,
  children,
}: CarouselWrapperProps) => {
  const { heroBanner } = useConfiguration();
  const { overlay } = heroBannerCarousel();

  return (
    <StyledWrapper isHero={isHero}>
      {title && !isHero && (
        <StyledTitle variant="h2" tag={titleTag}>
          {title}
        </StyledTitle>
      )}
      <HeroBannerWrapper isHero={isHero} windowWidth={windowWidth} shouldBeInfinite={shouldBeInfinite}>
        <SliderArrow
          isHero={isHero}
          isInfinite={shouldBeInfinite}
          windowWidth={windowWidth}
          side="left"
          handleClick={() => {
            if (sliderRef.current) {
              sliderRef.current.slickPrev();
            }
          }}
        />
        {!isHero ? (
          children
        ) : (
          <StyledHeroContentWrapper
            $maskPresent={heroBanner?.mask}
            $desktopMask={toDataUrl(HeroBannerMaskInverted.Desktop)}
            $tabletMask={toDataUrl(HeroBannerMaskInverted.Tablet)}
            $mobileMask={toDataUrl(HeroBannerMaskInverted.Mobile)}
          >
            {children}
          </StyledHeroContentWrapper>
        )}
        <SliderArrow
          isHero={isHero}
          isInfinite={shouldBeInfinite}
          windowWidth={windowWidth}
          side="right"
          handleClick={() => {
            if (sliderRef.current) {
              sliderRef.current.slickNext();
            }
          }}
        />
      </HeroBannerWrapper>
    </StyledWrapper>
  );
};

const Carousel = ({ title, titleTag, contentEntries, carouselType }: CarouselProps) => {
  const config = useConfiguration();
  const isHero = carouselType === 'Hero';
  const isLogo = carouselType === 'Logo Card';
  const sliderRef = useRef<Slider>(null);
  const [windowWidth, setWindowWidth] = useState<number>(0);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowWidth(window.innerWidth);

      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  const shouldBeInfinite = useCallback(() => {
    if (isHero && contentEntries.length > 1) {
      return true;
    }

    if (isLogo && contentEntries.length > 4) {
      return true;
    }

    if (!isHero && !isLogo && contentEntries.length > 3) {
      return true;
    }

    return false;
  }, [contentEntries.length, isHero, isLogo]);

  const settings = useMemo(
    () => getSliderSettings(isHero, isLogo, config.heroBanner?.mask),
    [isHero, isLogo, config.heroBanner?.mask],
  );

  if (carouselType === 'Hero') {
    return (
      <CarouselWrapper
        title={title}
        titleTag={titleTag}
        isHero={true}
        windowWidth={windowWidth}
        shouldBeInfinite={shouldBeInfinite()}
        sliderRef={sliderRef}
      >
        <Slider {...settings} ref={sliderRef}>
          {contentEntries.map((entry, id) => {
            return (
              <HeroBanner
                cta={entry.cta}
                ctaStyle={entry.ctaStyle}
                image={entry.image}
                key={entry.key}
                secondCta={entry.secondCta}
                secondCtaStyle={entry.secondCtaStyle}
                title={entry.title}
                alignText={entry.alignText}
                partOfCarousel={true}
                titleTag="div"
              />
            );
          })}
        </Slider>
      </CarouselWrapper>
    );
  }

  if (carouselType === 'Logo Card') {
    return (
      <CarouselWrapper
        title={title}
        titleTag={titleTag}
        isHero={false}
        windowWidth={windowWidth}
        shouldBeInfinite={shouldBeInfinite()}
        sliderRef={sliderRef}
      >
        <Slider {...settings} ref={sliderRef}>
          {contentEntries.map((entry, id) => {
            return <LogoCard image={entry.image} cta={entry.cta} title={entry.title} key={id.toString()} />;
          })}
        </Slider>
      </CarouselWrapper>
    );
  }

  return (
    <CarouselWrapper
      title={title}
      titleTag={titleTag}
      isHero={false}
      windowWidth={windowWidth}
      shouldBeInfinite={shouldBeInfinite()}
      sliderRef={sliderRef}
    >
      <Slider {...settings} ref={sliderRef}>
        {contentEntries.map((entry, id) => {
          return (
            <StyledCardWrapper key={id}>
              <CarouselItemCard
                key={id.toString()}
                ctaLabel={entry.ctaLabel}
                description={entry.description}
                image={entry.image}
                link={entry.link}
                title={entry.title}
              />
            </StyledCardWrapper>
          );
        })}
      </Slider>
    </CarouselWrapper>
  );
};

export default withErrorBoundary(Carousel, { componentName: 'Carousel' });
