import { NewsLetterHeader, Swoosh } from '@arvesta-websites/icons';

import { Config } from '../default-config';
import mergeConfig from '../merge-config';

const config: Config = mergeConfig({
  ContactFormHeading: NewsLetterHeader.Proxani,
  NewsletterHeader: {
    SVG: NewsLetterHeader.Proxani,
    className: 'newsletter-svg',
  },
  footer: {
    svg: {
      Desktop: Swoosh,
      Mobile: Swoosh,
      Tablet: Swoosh,
    },
  },
  button: {
    dealerOverview: {
      navigation: 'secondary',
      details: 'primary',
    },
  },
});

export default config;
