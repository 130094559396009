/* eslint-disable sort-keys-fix/sort-keys-fix */
import Logo from './Logo';
import LogoMobile from './LogoMobile';

// BASICS
export const baseColors = {
  black: '#000',
  bodyCopy: '#414042',
  primary: '#25002D',
  gray: [
    '#fff', // 0 index
    '#f8f8f8',
    '#dedede',
    '#979797',
    '#3d3d3d',
    '#414042',
  ],
  secondary: '#BD8336',
  highlighted: '#E9B84F',
  tertiary: '#DEC19A',
  red: '#f00101',
  white: '#fff',
};

export const colors = {
  ...baseColors,
  background: baseColors.white,
  danger: baseColors.red,
  dark: baseColors.gray[5],
  darkGray: baseColors.gray[5],
  info: baseColors.primary,
  light: baseColors.gray[2],
  lightGray: baseColors.gray[2],
  muted: baseColors.gray[1],
  primaryWhite: baseColors.white,
  success: baseColors.primary,
  text: baseColors.gray[5],
  textMuted: baseColors.gray[3],
  warning: baseColors.highlighted,
};

export const fonts = {
  body: 'figtree, sans-serif',
  heading: 'figtree, sans-serif',
};

export const fontWeights = {
  body: 400,
  bold: 700,
  display: 300,
  heading: 400,
  headingAlt: 600,
  light: 300,
  normal: 400,
};

export const fontSizes = {
  0: '0.75rem',
  1: '0.875rem',
  2: '1rem',
  3: '1.313rem',
  4: '1.5rem',
  5: '1.75rem',
  6: '1.875rem',
  7: '2.5rem',
  8: '2.813rem',
  9: '4.5rem',
  10: '5.5rem',
  11: '6rem',
};

export const lineHeights = {
  body: 1.5,
  heading: 1.5,
  paragraph: 1.75,
};

export const sizes = {
  lg: '1150px',
  md: '720px',
  sm: '540px',
  xl: '1440px',
};

export const radii = {
  default: '0.25rem',
  pill: '50rem',
  lg: '0.3rem',
  sm: '0.2rem',
};

export const shadows = {
  default: '0 .5rem 1rem rgba(0, 0, 0, .15)',
  lg: '0 1rem 3rem rgba(0, 0, 0, .175)',
  sm: '0 .125rem .25rem rgba(0, 0, 0, .075)',
};

const heading = {
  fontFamily: fonts.heading,
  fontWeight: fontWeights.heading,
};

const display = {
  fontWeight: fontWeights.display,
  lineHeight: lineHeights.heading,
};

// variants
const typeStyles = {
  heading,
  display,
};

export const text = {
  caps: {
    letterSpacing: '0.2em',
    textTransform: 'uppercase',
  },
  heading: {
    fontFamily: fonts.heading,
    fontWeight: fontWeights.heading,
    textTransform: 'uppercase',
  },
};

export const styles = {
  root: {
    fontFamily: fonts.body,
    fontWeight: fontWeights.display,
    backgroundColor: colors.white,
  },
  a: {
    '&:hover': {
      color: colors.primary,
    },
    color: colors.darkGray,
    textDecoration: 'underline',
  },
  blockquote: {
    backgroundColor: colors.tertiary,
    color: colors.text,
    textTransform: 'normal',
    fontWeight: '500',
    fontStyle: 'normal',
  },
  h1: {
    ...heading,
    fontSize: { desktop: '45px', tablet: '35px', mobile: '30px' },
    fontWeight: 800,
    lineHeight: { desktop: '54px', tablet: '42px', mobile: '36px' },
  },
  h2: {
    ...heading,
    fontSize: { desktop: '40px', tablet: '30px', mobile: '25px' },
    fontWeight: 800,
    lineHeight: { desktop: '48px', tablet: '36px', mobile: '31px' },
  },
  h3: {
    ...heading,
    fontSize: { desktop: '30px', tablet: '21px', mobile: '21px' },
    fontWeight: 700,
    lineHeight: { desktop: '36px', tablet: '25px', mobile: '25px' },
  },
  h4: {
    ...heading,
    fontSize: { desktop: '24px', tablet: '20px', mobile: '18px' },
    fontWeight: 800,
    lineHeight: { desktop: '29px', tablet: '24px', mobile: '22px' },
  },
  h5: {
    ...heading,
    fontSize: { desktop: '21px', tablet: '19px', mobile: '16px' },
    fontWeight: 600,
    lineHeight: { desktop: '25px', tablet: '24px', mobile: '19px' },
  },
  highlight: {
    backgroundColor: colors.secondary,
    color: colors.white,
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
  },
  inlineCode: {
    color: colors.darkGray,
  },
  li: {
    bulletColor: colors.secondary,
    bulletBorderRadius: '10px',
  },
  p: {
    margin: '0',
    marginBottom: '1.7rem',
    lineHeight: lineHeights.paragraph,
    fontSize: { desktop: '16px', tablet: '14px', mobile: '12px' },
    fontWeight: 400,
  },
  rteHighlight: {
    hoverColor: colors.highlighted,
    hoverFooter: colors.primary,
    color: colors.secondary,
    fontWeight: '700',
    textDecoration: 'underline',
    isFooter: colors.white,
  },
  table: {
    borderCollapse: 'collapse',
    color: colors.gray[3],
    marginBottom: '3rem',
    width: '100%',
  },
  th: {
    verticalAlign: 'bottom',
    borderTopWidth: '2px',
    borderTopStyle: 'solid',
    borderTopColor: colors.gray[3],
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    borderBottomColor: colors.gray[3],
    padding: '.75rem',
    textAlign: 'inherit',
  },
  td: {
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.gray[3],
    verticalAlign: 'top',
    padding: '.75rem',
  },
};

const buttonDefaults = {
  borderRadius: '0px',
  cursor: 'pointer',
  fontFamily: fonts.body,
  fontSize: fontSizes[1],
  fontWeight: 700,
  height: 'auto',
  padding: '13px 35px 12px',
};

export const buttons = {
  disabled: {
    ...buttonDefaults,
    backgroundColor: colors.muted,
    color: colors.textMuted,
    cursor: 'default',
  },
  primary: {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: colors.primary,
      borderColor: colors.primary,
    },
    backgroundColor: colors.secondary,
    borderColor: colors.secondary,
    borderStyle: 'solid',
    borderWidth: '2px',
    color: colors.white,
  },
  'primary-with-background': {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: colors.primary,
      borderColor: colors.primary,
      color: colors.white,
    },
    backgroundColor: colors.white,
    borderColor: colors.white,
    borderStyle: 'solid',
    borderWidth: '2px',
    color: colors.secondary,
  },
  secondary: {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: colors.white,
      borderColor: colors.primary,
      color: colors.primary,
    },
    backgroundColor: colors.white,
    borderColor: colors.secondary,
    borderStyle: 'solid',
    borderWidth: '2px',
    color: colors.secondary,
  },
  tertiary: {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: colors.white,
      borderColor: colors.white,
      color: colors.primary,
    },
    backgroundColor: 'transparent',
    borderColor: colors.white,
    borderStyle: 'solid',
    borderWidth: '2px',
    color: colors.white,
  },
};

// COMPONENTS
export const adviceCardModule = {
  backgroundColor: colors.secondary,
};

export const articleDetail = {
  introduction: colors.text,
  tags: {
    backgroundColor: colors.secondary,
    color: colors.white,
  },
  title: colors.text,
};

export const articleListing = {
  background: colors.secondary,
  filter: colors.text,
  hover: colors.primary,
  mobile: {
    title: colors.secondary,
    text: colors.text,
    background: colors.tertiary,
  },
  text: colors.white,
  title: colors.text,
};

export const breadcrumb = {
  backgroundColor: colors.tertiary,
};

export const cardCtas = {
  arrowColor: colors.secondary,
  arrowHoverColor: colors.primary,
  color: colors.primary,
  fontSize: '0.75rem',
  hoverColor: colors.primary,
  letterSpacing: '2px',
  lineHeight: '13px',
  textTransform: 'uppercase',
};

export const cards = {
  article: {
    description: colors.text,
    link: {
      '&:hover': {
        color: colors.primary,
        textDecoration: 'underline',
        opacity: 1,
      },
      color: colors.secondary,
    },
    title: colors.text,
  },
  logo: {
    color: colors.text,
  },
};

export const carousel = {
  controls: {
    regular: {
      backgroundColor: colors.secondary,
      color: colors.white,
      hover: {
        backgroundColor: colors.primary,
        color: colors.white,
      },
    },
    hero: {
      backgroundColor: colors.white,
      color: colors.secondary,
      stroke: colors.white,
      opacity: '0.8',
      maskArrowOffsets: {
        top: ['calc(50% - 6px)', null, null, 'calc(50% - 57px)', 'calc(50% - 57px)'],
        horizontal: ['', '0', '40px', '80px'],
      },
      hover: {
        backgroundColor: colors.white,
        color: colors.secondary,
        stroke: colors.white,
      },
    },
  },
  indicator: colors.primary,
  link: {
    '&:hover': {
      color: colors.primary,
      opacity: 1,
      textDecoration: 'underline',
    },
    color: colors.secondary,
  },
  text: colors.text,
};

export const categoryCards = {
  backgroundColor: colors.secondary,
};

export const categorySection = {
  background: colors.secondary,
  boxShadow: null,
  chevron: {
    fill: colors.white,
    stroke: colors.secondary,
  },
  color: colors.primaryWhite,
  date: colors.secondary,
  dateColor: colors.primaryWhite,
  headingColor: colors.dark,
};

export const columns = {
  imageCard: colors.text,
  imageCardTitle: colors.text,
  infoBlock: {
    background: colors.tertiary,
    button: {
      ...buttonDefaults,
      '&:hover': {
        backgroundColor: colors.primary,
        textDecoration: 'none',
      },
      backgroundColor: colors.secondary,
      color: colors.white,
    },
    description: colors.text,
    title: colors.text,
  },
};

export const contactForm = {
  heading: {
    color: colors.secondary,
  },
};

export const contacts = {
  office: {
    mapTextColor: colors.secondary,
    mapTextFontSize: '0.875rem',
    mapTextFontWeight: 'normal',
    mapTextLetterSpacing: '2.33px',
    mapTextLineHeight: '1.71',
    mapTextTransform: 'uppercase',
    openingHoursFontSize: '1rem',
  },
  description: colors.text,
  title: colors.text,
};

export const cookieBanner = {
  backgroundColor: colors.secondary,
};

export const ctaBanner = {
  focusImage: {
    backgroundColor: colors.secondary,
    color: colors.white,
    titleColor: colors.white,
  },
  focusText: {
    backgroundColor: colors.tertiary,
    color: colors.text,
    titleColor: colors.text,
  },
};

export const ctaGroup = {
  title: colors.text,
  chevron: {
    opacity: 1,
  },
};

export const customBlockCards = {
  backgroundColor: colors.primary,
  color: colors.primary,
};

export const dealer = {
  textColor: colors.secondary,
};

export const documentDownload = {
  arrowColor: colors.secondary,
  arrowHoverColor: colors.primary,
  color: colors.secondary,
  hoverColor: colors.primary,
};

export const footer = {
  activeColor: colors.primary,
  backgroundColor: colors.white,
  color: colors.primary,
  minHeight: '160px',
};

export const formInputs = {
  activeColor: colors.secondary,
  borderRadius: '0px',
  label: {
    fontSizes: ['12px', null, '16px'],
    lineHeights: ['21px', null, '26px'],
  },
  radioInputBorderRadius: '50%',
  radioSpanBorderRadius: '50%',
};

export const header = {
  backgroundColor: colors.white,
  color: colors.darkGray,
  activeColor: colors.primary,
};

export const headerBottom = {
  activeColor: colors.white,
  activeFontWeight: 'bold',
  backgroundColor: colors.secondary,
  color: colors.white,
  fontSize: '12px',
  fontWeight: 'normal',
};

export const heroBannerButton = {
  'No Background': {
    '&:hover': {
      backgroundColor: colors.white,
      borderColor: colors.white,
      color: colors.secondary,
    },
    backgroundColor: 'transparent',
    borderColor: colors.white,
    borderStyle: 'solid',
    borderWidth: '2px',
    color: colors.white,
    height: '42px',
    justifyContent: 'center',
  },
  'White Background': {
    '&:hover': {
      backgroundColor: colors.secondary,
      border: 'none',
      color: colors.white,
    },
    backgroundColor: colors.secondary,
    color: colors.white,
    justifyContent: 'center',
    minHeight: '42px',
    padding: '13px 35px 12px',
  },
  arrow: {
    display: 'none',
  },
};

export const heroSection = {
  titleFontWeight: 'normal',
};

export const imageAndText = {
  color: colors.secondary,
};

export const infoBlock = {
  background: colors.tertiary,
  button: {
    '&:hover': {
      backgroundColor: colors.primary,
      textDecoration: 'none',
    },
    backgroundColor: colors.secondary,
    color: colors.white,
  },
  description: colors.text,
  title: colors.text,
};

export const infoSection = {
  backgroundColor: colors.primary,
  color: colors.white,
  title: colors.white,
};

export const ingredients = {
  backgroundColor: colors.secondary,
  color: colors.white,
  hoverColor: colors.primary,
};

export const media = {
  backgroundColor: colors.primary,
  titleFontWeight: 'bold',
  titleTextTransform: 'normal',
};

export const mobileNavigation = {
  arrowColor: colors.secondary,
  chevronColor: colors.secondary,
};

export const navItems = {
  fontSize: '0.75rem',
  letterSpacing: '2px',
};

export const newsListing = {
  titleFontWeight: 'normal',
};

export const ownerDetail = {
  introduction: colors.text,
  storeCard: {
    background: colors.tertiary,
  },
  tags: {
    backgroundColor: colors.secondary,
    color: colors.white,
  },
  title: colors.text,
};

export const product = {
  backgroundColor: colors.primary,
  color: colors.white,
};

export const productListing = {
  titleFontWeight: 'normal',
};

export const salesPerson = {
  borderColor: colors.secondary,
};

export const search = {
  activeColor: colors.secondary,
  borderRadius: '20px',
};

export const shortBanner = {
  backgroundColor: colors.secondary,
  color: colors.white,
  titleFontWeight: 'bold',
  titleTextTransform: 'none',
};

export const table = {
  headerBackgroundColor: colors.primary,
  headerColor: colors.white,
  scrollbarColor: colors.primary,
};

export const upComingEvents = {
  backgroundColor: colors.secondary,
  color: colors.white,
};

export default {
  adviceCardModule,
  articleDetail,
  articleListing,
  breadcrumb,
  buttons,
  cardCtas,
  cards,
  carousel,
  categoryCards,
  categorySection,
  colors,
  columns,
  contactForm,
  contacts,
  cookieBanner,
  ctaBanner,
  ctaGroup,
  customBlockCards,
  dealer,
  documentDownload,
  fontSizes,
  fonts,
  fontWeights,
  footer,
  formInputs,
  header,
  headerBottom,
  heroBannerButton,
  heroSection,
  imageAndText,
  infoBlock,
  infoSection,
  ingredients,
  lineHeights,
  logo: Logo,
  logoMobile: LogoMobile,
  media,
  mobileNavigation,
  name: 'Equilannoo',
  navItems,
  newsListing,
  ownerDetail,
  product,
  productListing,
  radii,
  salesPerson,
  search,
  shadows,
  shortBanner,
  sizes,
  styles,
  table,
  text,
  typeStyles,
  upComingEvents,
};
