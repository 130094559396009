import { useConfiguration } from '@arvesta-websites/configuration';
import { Cart as CartIcon, HamburgerBrandX as HamburgerIcon, SearchLarge as SearchIcon } from '@arvesta-websites/icons';
import React, { useContext, useState } from 'react';
import { Box, FlexProps } from 'rebass';
import { tv } from 'tailwind-variants';

import type { LocaleType, NavItemType } from '../../../../types';
import { useScrollDirection } from '../../../../utils';
import { Breadcrumb, type BreadcrumbProps } from '../../Breadcrumb';
import Cta from '../../Cta';
import { withErrorBoundary } from '../../ErrorBoundary';
import Logo from '../../Logo';
import { LangSwitcherDefault } from '../LangSwitcher';
import { LocationContext } from '../Location';
import matchUrl from '../matchUrl';
import { MobileSearch } from '../MobileSearch';
import { PrimaryNavigationDefault } from '../PrimaryNavigation';
import { SearchDefault } from '../Search';
import { SecondaryNavigationDefault } from '../SecondaryNavigation';
import { SecondaryNavigationMobileDefault } from '../SecondaryNavigationMobile';

export interface HeaderProps extends FlexProps {
  siteName: string;
  navigation: NavItemType[];
  ribonLinks?: NavItemType[];
  defaultLocale: LocaleType;
  activeLocales: string[];
  searchFunction: (searchTerm: string) => void;
  variant?: 'default' | 'display search & language selector in separate bar' | 'display top-level navigation';
  isHomepage: boolean;
  breadcrumb?: Omit<BreadcrumbProps, 'locale'>;
}

export const HEADERDEFAULT_VARIANTS = {
  default: 'default',
  secondary: 'display search & language selector in separate bar',
  topLevel: 'display top-level navigation',
} as const;
type NavItemTypeWithDividedChildren = NavItemType & { isDivided?: boolean };

const headerNavigation = tv({
  slots: {
    cartIcon: 'mr-[5px] h-[17px] w-[17px]',
    header: 'fixed top-0 w-full text-content z-50',
    headerBottom:
      'w-[962px] bg-header z-50 fixed left-2/4 lg:top-[95px] pt-3.5 transform -translate-x-1/2 hidden lg:flex',
    headerInnerContainer: 'min-h-[77px]',
    headerTop:
      'flex my-0 mx-auto items-center justify-between max-w-screen-xl px-[20px] lg:px-[5px] py-0 xl:px-12 min-h-[75px] lg:min-h-[95px] gap-[20px]',
    headerTopRibbon: 'max-w-screen-xl min-h-8 my-0 mx-auto flex justify-end items-center gap-[25px] pr-7',
    headerTopRibbonItem:
      'h-8 text-white hover:text-header-ribbon-hover ribonItem no-underline underline-offset-base flex items-center whitespace-nowrap hover:underline opacity-100',
    headerTopSecondaryVariant: 'bg-gray-300 py-1.5 px-0 hidden lg:block',
    headerTopSecondaryVariantInner: 'max-w-screen-xl my-0 mx-auto flex justify-end py-0 px-5',
    icon: 'justify-center items-center flex h-full w-full',
    iconSvg: 'fill-header-icon',
    innerRibbon: 'bg-header-ribbon hidden lg:block',
    logo: 'py-[5px]',
    logoWrapper: 'hidden lg:block shrink logoWrapper',
    mobileIcon: 'justify-center items-center self-stretch cursor-pointer flex lg:hidden',
    mobileIconsWrapper: 'justify-between w-full flex lg:hidden',
    mobileNavigation: 'fixed z-50 left-0 top-0 h-full w-full bg-black flex flex-col overflow-hidden',
    rightSection: 'justify-end hidden lg:flex shrink',
  },
  variants: {
    isSearchMenu: {
      true: {
        mobileIcon: 'self-stretch',
      },
    },
  },
});

const renderBrandxNavigation = (
  activeMenuItem: NavItemType | undefined,
  locale: LocaleType,
  ribbonPresent: boolean,
) => {
  const { headerBottom } = headerNavigation();
  const overrides = ribbonPresent ? { class: 'lg:top-[127px]' } : undefined;

  if (activeMenuItem?.children?.length) {
    const combinedChildren = [
      ...(activeMenuItem?.children || []),
      ...(activeMenuItem?.childrenDivided || []),
    ] as NavItemTypeWithDividedChildren[];
    if (activeMenuItem?.childrenDivided?.length) {
      combinedChildren[activeMenuItem.children.length].isDivided = true;
    }

    return (
      <div className={headerBottom(overrides)}>
        <SecondaryNavigationDefault locale={locale} items={combinedChildren} />
      </div>
    );
  }

  return null;
};

// Could maybe tidy this up
const renderSecondaryMobileNavigation = (
  isVisible: boolean,
  menuItems: Array<NavItemType>,
  closeNav: () => void,
  defaultLocale: LocaleType,
  activeLocales: Array<string>,
  ribonLinks?: Array<NavItemType>,
) => {
  const { mobileNavigation } = headerNavigation();
  if (isVisible) {
    return (
      <div className={mobileNavigation()}>
        <SecondaryNavigationMobileDefault
          activeLocales={activeLocales}
          defaultLocale={defaultLocale}
          items={menuItems}
          onClose={() => {
            closeNav();
          }}
          ribonLinks={ribonLinks}
        />
      </div>
    );
  }

  return null;
};

const Header = ({
  siteName,
  navigation,
  ribonLinks,
  defaultLocale,
  activeLocales,
  searchFunction,
  variant,
  isHomepage,
  breadcrumb,
}: HeaderProps) => {
  const config = useConfiguration();
  const {
    breadcrumb: { display: displayBreadcrumb },
  } = useConfiguration();
  const currentLocation = useContext(LocationContext);
  const [currentPageUrl] = useState(currentLocation.pathname);
  const [visible, setVisible] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [currentItem, setCurrentItem] = useState({} as NavItemType);
  const [mobileSecondaryMenuVisible, setMobileSecondaryMenuVisible] = useState(false);
  const [mobileSearchVisible, setMobileSearchVisible] = useState(false);
  const direction = useScrollDirection({ initialDirection: 'up', off: false, thresholdPixels: 50 });
  const FEED_EXPRESS = 'FeedExpress';

  const overridesMobile = config.header.mobileLogo
    ? { class: 'bg-header-mobile lg:bg-header' }
    : { class: 'bg-header' };

  React.useEffect(() => {
    if (loaded) {
      setVisible(direction === 'up');
    }
    setLoaded(true);
  }, [direction]);

  const activeMenuItem = navigation.find(navItem => {
    if (!currentPageUrl) {
      return false;
    }
    return matchUrl(currentPageUrl, navItem);
  });

  const secondary = (activeMenuItem && activeMenuItem.children) || null;
  const classes = [];

  const handleNavItemClicked = (navItem: NavItemType) => {
    if (navItem.children?.length) {
      setCurrentItem(navItem);
    } else {
      setCurrentItem({} as NavItemType);
    }
  };

  const {
    cartIcon,
    header,
    headerInnerContainer,
    headerTop,
    headerTopSecondaryVariant,
    headerTopSecondaryVariantInner,
    headerTopRibbon,
    headerTopRibbonItem,
    icon,
    iconSvg,
    innerRibbon,
    logo,
    logoWrapper,
    mobileIconsWrapper,
    mobileIcon,
    rightSection,
  } = headerNavigation();

  if (secondary) {
    classes.push('has-secondary');
  }

  if (loaded) {
    if (!visible) {
      classes.push('is-hidden');
    }
  }

  const shouldRenderBreadcrumb = displayBreadcrumb && !isHomepage && !!breadcrumb?.crumbs;
  const breadCrumbMargin = ribonLinks?.length
    ? ['86px', null, null, '127px', null]
    : ['86px', null, null, '95px', null];
  const brandXNavigation = renderBrandxNavigation(currentItem, defaultLocale, !!ribonLinks?.length);

  return (
    <>
      <div className={header()}>
        {variant === HEADERDEFAULT_VARIANTS.secondary && (
          <div className={headerTopSecondaryVariant()}>
            <div className={headerTopSecondaryVariantInner()}>
              <SearchDefault
                searchFunction={searchTerm => {
                  searchFunction(searchTerm);
                }}
                variant={variant}
              />
              <LangSwitcherDefault activeLocales={activeLocales} defaultLocale={defaultLocale.id} variant={variant} />
            </div>
          </div>
        )}
        {!ribonLinks?.length ? null : (
          <div className={innerRibbon()}>
            <div className={headerTopRibbon()}>
              {ribonLinks?.map(link => (
                <Cta to={link?.externalUrl || link.to} key={link.label} className={headerTopRibbonItem()}>
                  {link.label === FEED_EXPRESS && <CartIcon className={cartIcon()} />}
                  {link.label}
                </Cta>
              ))}
            </div>
          </div>
        )}
        <div className={headerInnerContainer(overridesMobile)}>
          <div className={headerTop()}>
            {mobileSearchVisible ? (
              <MobileSearch
                onClose={() => setMobileSearchVisible(false)}
                searchFunction={searchTerm => {
                  searchFunction(searchTerm);
                }}
              />
            ) : (
              <>
                <div className={logoWrapper()}>
                  <Logo
                    defaultLocale={defaultLocale}
                    siteName={siteName}
                    variant={variant}
                    height={config?.header?.height}
                  />
                </div>
                <PrimaryNavigationDefault
                  activeMenuItem={activeMenuItem || null}
                  currentItem={currentItem}
                  handleClickMenuItem={handleNavItemClicked}
                  items={navigation}
                  variant={variant}
                  defaultLocale={defaultLocale}
                />
                {variant !== HEADERDEFAULT_VARIANTS.secondary && (
                  <div className={rightSection()}>
                    <SearchDefault
                      searchFunction={searchTerm => {
                        searchFunction(searchTerm);
                      }}
                      variant={variant}
                    />
                    <LangSwitcherDefault
                      activeLocales={activeLocales}
                      defaultLocale={defaultLocale.id}
                      variant={variant}
                    />
                  </div>
                )}
                <div className={mobileIconsWrapper()}>
                  <div className={mobileIcon()}>
                    <span
                      onClick={() => setMobileSecondaryMenuVisible(!mobileSecondaryMenuVisible)}
                      onKeyUp={() => setMobileSecondaryMenuVisible(!mobileSecondaryMenuVisible)}
                      role="button"
                      tabIndex={0}
                      className={icon()}
                    >
                      <HamburgerIcon className={iconSvg()} />
                    </span>
                  </div>
                  <Box className={logo()}>
                    <Logo defaultLocale={defaultLocale} siteName={siteName} variant={variant} isMobile />
                  </Box>
                  <div className={mobileIcon({ isSearchMenu: true })}>
                    <span
                      onClick={() => setMobileSearchVisible(!mobileSecondaryMenuVisible)}
                      onKeyUp={() => setMobileSearchVisible(!mobileSecondaryMenuVisible)}
                      role="button"
                      tabIndex={0}
                      className={icon()}
                    >
                      <SearchIcon className={iconSvg()} />
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {shouldRenderBreadcrumb && <Breadcrumb {...breadcrumb} locale={defaultLocale} marginTop={breadCrumbMargin} />}
      {brandXNavigation && <div onMouseLeave={() => setCurrentItem({} as NavItemType)}>{brandXNavigation}</div>}

      {renderSecondaryMobileNavigation(
        mobileSecondaryMenuVisible,
        navigation,
        setMobileSecondaryMenuVisible as () => void,
        defaultLocale,
        activeLocales,
        ribonLinks,
      )}
    </>
  );
};

export default withErrorBoundary(Header, { componentName: 'Header' });
