import React from 'react';

const ChevronLeftSlider = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props}>
    <g id="Group_2361" data-name="Group 2361" transform="translate(-662 -313.916)">
      <path
        className="iconCircle"
        id="Path_2231"
        data-name="Path 2231"
        d="M16,32A16,16,0,1,0,0,16,16,16,0,0,0,16,32Z"
        transform="translate(662 313.916)"
      />
      <path
        className="iconArrow"
        id="Path_2232"
        data-name="Path 2232"
        d="M0,5.018,5.018,0l5.018,5.018"
        transform="translate(674.509 334.582) rotate(-90)"
        fill="none"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default ChevronLeftSlider;
