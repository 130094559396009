import { useConfiguration } from '@arvesta-websites/configuration';
import { CheckBoxCheck } from '@arvesta-websites/icons';
import React from 'react';
import { useIntl } from 'react-intl';
import { tv } from 'tailwind-variants';

import { EMAIL_REGEX } from '../../../utils';
import { Button, withErrorBoundary } from '../../components';

type NewsletterState = {
  checked: boolean;
  email: string;
  emailError: boolean;
  success: boolean;
  termsError: boolean;
};

type NewsletterAction =
  | {
      type: 'SET_CHECKED' | 'SET_TERMS_ERROR' | 'SET_EMAIL_ERROR' | 'SET_SUCCESS';
      payload: boolean;
    }
  | {
      type: 'SET_EMAIL';
      payload: string;
    };

export const newsletterReducer = (state: NewsletterState, action: NewsletterAction) => {
  switch (action.type) {
    case 'SET_CHECKED':
      return {
        ...state,
        checked: action.payload,
      };
    case 'SET_TERMS_ERROR':
      return {
        ...state,
        termsError: action.payload,
      };
    case 'SET_EMAIL_ERROR':
      return {
        ...state,
        emailError: action.payload,
      };
    case 'SET_SUCCESS':
      return {
        ...state,
        success: action.payload,
      };
    case 'SET_EMAIL':
      return {
        ...state,
        email: action.payload,
      };
    default:
      return state;
  }
};

export const newsletterInitialState = {
  checked: false,
  email: '',
  emailError: false,
  success: false,
  termsError: false,
};

export const createNewsletterFormSubmitHandler =
  (
    newsletterState: any,
    dispatch: React.Dispatch<NewsletterAction>,
    handleNewsletterFormSubmission: (...args: any[]) => Promise<any>,
    locale: any,
    newsletter?: Omit<NewsLetterProps, 'submitHandler' | 'state' | 'setChecked' | 'setEmail'>,
  ) =>
  async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!EMAIL_REGEX.test(newsletterState.email) || newsletterState.email === '') {
      dispatch({ payload: '', type: 'SET_EMAIL' });
      dispatch({ payload: true, type: 'SET_EMAIL_ERROR' });
    } else {
      dispatch({ payload: false, type: 'SET_EMAIL_ERROR' });
    }

    if (!newsletterState.checked) {
      dispatch({ payload: true, type: 'SET_TERMS_ERROR' });
    } else {
      dispatch({ payload: false, type: 'SET_TERMS_ERROR' });
    }

    if (EMAIL_REGEX.test(newsletterState.email) && newsletterState.checked) {
      const res = await handleNewsletterFormSubmission(
        newsletterState.email,
        'BE',
        locale.id,
        newsletter?.campaignName ?? '',
      );
      if (res === 200) {
        dispatch({ payload: '', type: 'SET_EMAIL' });
        dispatch({ payload: true, type: 'SET_SUCCESS' });
      }
    }
  };

export type NewsLetterProps = {
  title?: string;
  description?: string;
  privacyPolicyUrl?: string;
  campaignName?: string;
  isFixedBottom?: boolean;
  hasFooterSVG?: boolean;
  submitHandler: (e: React.SyntheticEvent) => void;
  state: {
    email: string;
    checked: boolean;
    termsError: boolean;
    emailError: boolean;
    success: boolean;
  };
  setChecked: (value: boolean) => void;
  setEmail: (value: string) => void;
};

const styles = tv({
  slots: {
    button:
      'bg-footer-button text-footer-button-text rounded-button cursor-pointer border-none newsletter-button w-full lg:w-[167px]',
    checkbox: 'min-w-[1.125rem] h-[1.125rem] newsletter-checkbox m-0 cursor-pointer appearance-none',
    checkboxCheck: 'absolute ml-[2px] hidden pointer-events-none newsletter-checkbox-check',
    checkboxLabel: 'text-footer-newsletter newsletter-checkbox-label',
    checkboxWrapper: 'items-center gap-2 hidden lg:flex',
    checkboxWrapperMobile: 'items-center gap-2 flex lg:hidden',
    description: 'text-footer-newsletter newsletter-description overflow-auto',
    input:
      'w-full newsletter-input opacity-input text-footer-newsletter-input bg-footer pl-6 lg:pl-[23px] max-[768px]:pl-[9px] focus:outline-none',
    internalWrapper: 'w-full newsletter-internal-wrapper',
    success: 'text-base text-center text-primary leading-[1.625rem]',
    title: 'absolute newsletter-title text-footer-newsletter break-words',
    titleWrapper: 'relative',
    url: 'text-footer-newsletter underline cursor-pointer whitespace-nowrap',
    wrapper: 'bg-footer-secondary mt-6 px-[1.125rem] pt-10 pb-12 flex flex-col w-full items-center',
    wrapperInput: 'flex flex-col gap-3 pb-[1.5rem] lg:flex-row lg:gap-[0.4375rem]',
  },

  variants: {
    checked: {
      true: {
        checkboxCheck: 'block',
      },
    },
    error: {
      true: {
        checkboxLabel: 'text-error',
        input: 'text-[11px] md:text-xs lg:text-sm text-error',
      },
    },
    isFixedBottom: {
      true: {
        wrapper: 'pb-0',
      },
    },
    hasFooterSVG: {
      true: {
        wrapper: 'pb-0',
      },
      false: {
        wrapper: 'pb-10',
      },
    },
  },
});

const NewsLetter = ({
  title,
  description,
  privacyPolicyUrl,
  campaignName,
  isFixedBottom = false,
  hasFooterSVG,
  state: { email, checked, termsError, emailError, success },
  setChecked,
  setEmail,
  submitHandler: handleSubmit,
  ...rest
}: NewsLetterProps) => {
  const intl = useIntl();
  const { NewsletterHeader } = useConfiguration();

  const {
    success: successMessage,
    internalWrapper,
    title: titleStyles,
    wrapper,
    wrapperInput,
    description: descriptionStyle,
    input,
    checkboxWrapperMobile,
    checkboxWrapper,
    checkboxCheck,
    checkbox,
    checkboxLabel,
    url,
    button,
    titleWrapper,
  } = styles();

  return (
    <div className={wrapper({ isFixedBottom, hasFooterSVG })} {...rest}>
      <div className={internalWrapper()}>
        <div className={titleWrapper()}>
          <div className={titleStyles(NewsletterHeader?.title)}>{title}</div>
          <NewsletterHeader.SVG className={NewsletterHeader.className} />
        </div>
        <div className={descriptionStyle()}>{description}</div>
        {success && <div className={successMessage()}>{intl.formatMessage({ id: 'newsletter.email.success' })}</div>}
        {!success && (
          <div>
            <div className={wrapperInput()}>
              <input
                type="email"
                className={input({ error: emailError })}
                onChange={e => setEmail(e.target.value)}
                placeholder={
                  emailError
                    ? intl.formatMessage({ id: 'newsletter.email.error' })
                    : intl.formatMessage({ id: 'newsletter.input.placeholder' })
                }
                value={email}
              />
              <div className={checkboxWrapperMobile()}>
                <CheckBoxCheck className={checkboxCheck({ checked })} />
                <input type="checkbox" className={checkbox()} checked={checked} onChange={() => setChecked(!checked)} />
                <label className={checkboxLabel({ error: termsError })}>
                  {termsError ? (
                    <div>{intl.formatMessage({ id: 'newsletter.terms.error' })}</div>
                  ) : (
                    <div>
                      {intl.formatMessage({ id: 'newsletter.terms.sectionOne' })}&nbsp;
                      <a href={privacyPolicyUrl} className={url()} rel="noreferrer" target="_blank">
                        {intl.formatMessage({ id: 'newsletter.terms.privacyPolicy' })}
                      </a>
                      &nbsp;{intl.formatMessage({ id: 'newsletter.terms.sectionTwo' })}
                    </div>
                  )}
                </label>
              </div>
              <Button variant="primary" className={button()} onClick={handleSubmit}>
                {intl.formatMessage({ id: 'newsletter.terms.subscribe' })}
              </Button>
            </div>
            <div className={checkboxWrapper()}>
              <CheckBoxCheck className={checkboxCheck({ checked })} />
              <input type="checkbox" className={checkbox()} checked={checked} onChange={() => setChecked(!checked)} />
              <label className={checkboxLabel({ error: termsError })}>
                {termsError ? (
                  <div>{intl.formatMessage({ id: 'newsletter.terms.error' })}</div>
                ) : (
                  <div>
                    {intl.formatMessage({ id: 'newsletter.terms.sectionOne' })}&nbsp;
                    <a href={privacyPolicyUrl} className={url()} rel="noreferrer" target="_blank">
                      {intl.formatMessage({ id: 'newsletter.terms.privacyPolicy' })}
                    </a>
                    &nbsp;{intl.formatMessage({ id: 'newsletter.terms.sectionTwo' })}
                  </div>
                )}
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withErrorBoundary(NewsLetter, { componentName: 'NewsLetter' });
